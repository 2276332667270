import React from 'react';
import LeaseIndexModal from './lease_index_modal.js.jsx';

export default class BasicHeaderFields extends React.Component {
  constructor(props) {
    super(props);

    this.state = { ...this.props };

    this._handleGetVendorOnline = this._handleGetVendorOnline.bind(this);
    this.autopayAvailable = this.autopayAvailable.bind(this);
    this._submitMoveOut = this._submitMoveOut.bind(this);
    this._cancelMoveOut = this._cancelMoveOut.bind(this);
    /* Template
        {
          parentComponent: React.Component,
          balance: '',
          subHeader: '',
          entityHeaderList: [
            {
              fullName: '',
              entityIconImage: '/imageurl.jpg'
            }
          ]
        }
    */
  }

  componentDidMount() {
    const that = this;
    startNewLoadingBar('accounting-main-content-header');
    this.state.parentComponent.updateHeaderFieldData = (newProps, callback) => {
      that.setState(newProps, callback);
    };
    new mdc.tabs.MDCTabBarScroller(
      document.getElementById('tab-bar-scroller--2')
    );
  }

  componentDidUpdate() {
    $('#anchor-div').addClass('updated');
  }

  autopayAvailable() {
    return (
      this.props.accountingPageType == 'tenant' ||
      this.props.accountingPageType == 'vendor'
    );
  }

  _handleGetVendorOnline() {
    var that = this;
    var url = '/vendors/' + that.state.entityId + '/get_vendor_online_redesign';
    $.ajax({
      url: url,
      type: 'GET',
      dataType: 'JSON',
      success: function (data) {
        if (data.errors == null) {
          alert(
            'Email notifications have been sent to vendors with online access.'
          );
        } else alert(data.errors);
      },
    });
  }

  _cancelMoveOut(closeDialogCallback) {
    var that = this;
    var dialog = $('.mdc-dialog');
    if (dialog.attr('id') === 'mdc-dialog-confirm-move-out') {
      closeDialogCallback();
    } else {
      closeDialogCallback();
      let url =
        '/leases/issue_refund_redesign/' +
        that.props.leaseId +
        '/' +
        $('#refund_bank_account').val();
      $.ajax({
        url: url,
        type: 'POST',
        data: { issue_refund: false },
        // return only the print move out form
        success: (data) => {
          initDialog(data.html);
          if (data.automatic_action_errors) {
            alert(data.automatic_action_errors);
          }
        },
      });
    }
  }

  _submitMoveOut(closeDialogCallback) {
    var that = this;
    var dialog = $('.mdc-dialog');
    if (dialog.attr('id') === 'mdc-dialog-confirm-move-out') {
      closeDialogCallback();
      $.ajax({
        url:
          '/leases/move_out_with_outstanding_balance_redesign/' +
          that.props.leaseId,
        type: 'POST',
        dataType: 'JSON',
        success: (data) => {
          initDialog(data.html);
          if (data.automatic_action_errors) {
            alert(data.automatic_action_errors);
          }
        },
        complete: function () {
          enableSubmit();
        },
      });
    } else {
      let check_fields = {};
      $('.check_fields').each(() => {
        var cid = $(this).data('company-id');
        if (cid == undefined) {
          cid = '0';
        }
        check_fields[cid] = {};
        check_fields[cid]['payment_date'] = $(this)
          .find('[ id ^= "refund_payment_date"]')
          .val();
        check_fields[cid]['check_number'] = $(this)
          .find('[ id ^= "check_number"]')
          .val();
      });
      let url =
        '/leases/issue_refund_redesign/' +
        that.props.leaseId +
        '/' +
        $('#refund_bank_account').val();
      closeDialogCallback();
      $.ajax({
        url: url,
        type: 'POST',
        data: {
          issue_refund: true,
          check_fields: check_fields,
        },
        success: (data) => {
          if (typeof data.errors !== 'undefined') alert(data.errors);
          if (typeof data.checkForm !== 'undefined') {
            initDialog(data.checkForm.html, {
              cancelFunction: that.cancelMoveOutDialog,
            });
          } else {
            initDialog(data.html);
          }
          if (data.automatic_action_errors) {
            alert(data.automatic_action_errors);
          }
        },
        complete: function () {
          enableSubmit();
        },
      });
    }
  }

  render() {
    var that = this;
    // Move the selected tenant to the leftmost place
    if (this.state.entityHeaderList > 0) {
      for (var i = 0; i < this.state.entityHeaderList.length; i++) {
        if (
          this.state.entityHeaderList[i].tenantId ===
          this.props.selectedEntityId
        ) {
          this.state.entityHeaderList = this.state.entityHeaderList
            .splice(i, 1)
            .concat(this.state.entityHeaderList);
          break;
        }
      }
    }
    var entityHeaders = (this.state.entityHeaderList || []).map(function (
      entity,
      idx
    ) {
      return (
        <BasicEntityInfo
          key={'entity_header_' + idx}
          entityIndex={idx}
          entityName={entity.fullName}
          entityIconImage={entity.image}
          subHeader={entity.subHeader}
          address={entity.address}
          role={entity.role}
          email={entity.email}
          emailHash={entity.emailHash}
          demoAccount={entity.demoAccount}
          number={entity.number}
          securityDeposit={entity.securityDeposit}
          entityId={entity.tenantId}
          pageType={that.state.accountingPageType}
          firstName={entity.firstName}
          lastName={entity.lastName}
          taxId={entity.taxId}
          active={entity.active}
          allowOnlineAccess={entity.onlineAccess}
          accountingPage={that.props.accountingPage}
          filterId={that.props.filterId}
          parentComponent={that.props.parentComponent}
        />
      );
    });

    let autoPayStatus = this.state.autopay ? 'ON' : 'OFF';
    if (
      this.state.leaseAutopayStatuses != null &&
      this.state.leaseAutopayStatuses[this.props.leaseId] != null
    ) {
      if (this.state.leaseAutopayStatuses[this.props.leaseId]) {
        autoPayStatus = 'ON';
      } else if (this.state.leaseAutopayStatusesSecondary[this.props.leaseId]) {
        autoPayStatus = 'ON - SECONDARY';
      } else if (this.state.leaseAutopayStatusesPrimary[this.props.leaseId]) {
        autoPayStatus = 'ON - PRIMARY';
      } else {
        autoPayStatus = 'OFF';
      }
    }

    return (
      <div>
        <div className="pm-flex pm-flex-nowrap pm-flex-space-between pm-entity-headers">
          <div className="pm-flex pm-flex-col pm-flex-grow pm-flex-shrink">
            <div className="pm-flex">{entityHeaders}</div>
            {/* <p id="entity-header-address" className="pm-text-color--grey5 pm-text-size-14">{this.state.subHeader}</p> */}
          </div>
          <BalanceCard
            balanceAmount={this.state.balance}
            autopayAvailable={this.autopayAvailable()}
            autoPayStatus={autoPayStatus}
            leaseId={this.props.leaseId}
            entityId={this.state.entityId}
            vendorRoleRelationshipId={this.state.vendorRoleRelationshipId}
            accountingPage={this.props.parentComponent}
          />
        </div>
        <MobileFriendlyBalanceCard
          balanceAmount={this.state.balance}
          autopayAvailable={this.autopayAvailable()}
          autoPayStatus={autoPayStatus}
        />
      </div>
    );
  }
}

class BasicEntityInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...this.props };
  }

  handleOpenToolTip(e) {
    $(e.currentTarget).addClass('open');
  }

  componentDidMount() {
    var that = this;
    if (that.state.emailHash && !that.state.demoAccount) {
      let avatarUrl =
        GLOBAL_GRAVATAR_URL +
        that.state.emailHash +
        '?d=' +
        GLOBAL_GRAVATAR_DEFAULT_URL;
      $.ajax({
        url: avatarUrl,
        type: 'GET',
        success: function () {
          that.setState({ avatarUrl: avatarUrl });
        },
      });
    }
  }

  render() {
    return (
      <div className="pm-flex pm-button-lockup">
        <div className="pm-flex pm-flex-nowrap pm-flex-align-center">
          <div className="pm-flex">
            <img
              src={this.state.avatarUrl || this.props.entityIconImage}
              className="pm-avatar--larger pm-hide-mobile pm-margin-right-16"
            />
            <div
              className="pm-flex pm-flex-col"
              style={{ marginTop: '0.625rem' }}
            >
              <h2
                className="entity-name pm-margin-bottom-8 pm-text-color--grey6"
                data-firstname={this.props.firstName}
                data-lastname={this.props.lastName}
              >
                {this.props.entityName}
              </h2>
              <p
                id="entity-header-address"
                className="pm-margin-none pm-text-color--grey5 pm-text-size-14"
              >
                {this.props.subHeader}
              </p>
            </div>
          </div>
          {!['other', 'imported'].includes(this.props.filterId) && (
            <div
              className="mdc-menu-anchor pm-tenantinfo-tooltip--parent"
              id="anchor-div"
            >
              <button
                key={'pm-tenant-button_' + this.state.entityIndex}
                id={'pm-tenant-button_' + this.state.entityIndex}
                onClick={this.handleOpenToolTip}
                className="pm-tenantinfo-tooltip-toggle mdc-button mdc-button--dense mdc-button-icon mdc-button-row pm-bg-color--blue1 pm-text-color--white"
                data-mdc-auto-init="MDCRipple"
              >
                <i className="mdi mdi-menu-down"></i>
              </button>

              {/* Using Date().getTime() for the key ensures a different key on each render, which forces the component to rerender. This was added as a workaround to the Fonality extension causing a JS error on the accounting pages by adding additional html. */}
              <EntityToolTip
                key={new Date().getTime()}
                entityName={this.props.entityName}
                address={this.props.address}
                role={this.props.role}
                email={this.props.email}
                number={this.props.number}
                securityDeposit={this.props.securityDeposit}
                entityId={this.props.entityId}
                pageType={this.props.pageType}
                firstName={this.props.firstName}
                lastName={this.props.lastName}
                taxId={this.props.taxId}
                active={this.props.active}
                allowOnlineAccess={this.props.onlineAccess}
                accountingPage={this.props.accountingPage}
                parentComponent={this.props.parentComponent}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export class EntityToolTip extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...this.props };

    this.handleEditInfo = this.handleEditInfo.bind(this);
    this.handleAllLeases = this.handleAllLeases.bind(this);
    this.handleAttachments = this.handleAttachments.bind(this);
    this.navigateToTenant = this.navigateToTenant.bind(this);
  }

  navigateToTenant() {
    if (this.state.entityId) {
      window.location.href =
        '/accounting/tenants?entity_id=' + this.state.entityId;
    }
  }

  handleEditInfo(e) {
    var pageType = this.state.pageType;
    $(e.target)
      .parents('.pm-tenantinfo-tooltip--parent')
      .find('.pm-tenantinfo-tooltip-toggle')
      .removeClass('open');
    startLoadingSpinner('pm-main-content');
    if (pageType === 'vendor') {
      $('#main-tab-Vendor').click();
      $('#nested-Details').click();
    } else if (pageType === 'tenant') {
      $('#nested-Details').click();
      $('#main-tab-Lease').click();
    } else if (pageType === 'owner') {
      $('#main-tab-Company').click();
    }
    this.props.accountingPage.setState(
      { expandableGroupListExpand: true },
      function () {
        stopLoadingSpinner('pm-main-content');
      }
    );
  }

  handleCloseToolTip(e) {
    $(e.currentTarget)
      .parent()
      .parent()
      .parent()
      .find('>:first-child')
      .removeClass('open');
  }

  handleAllLeases() {
    startPageLoadingSpinner();
    var that = this;
    var leaseId;

    if (that.props.parentComponent.state.selectedLeaseId)
      leaseId = that.props.parentComponent.state.selectedLeaseId;
    else leaseId = that.props.parentComponent.state.id;

    $.ajax({
      url: `/tenants/${leaseId}/all_leases_redesign`,
      type: 'GET',
      dataType: 'JSON',
      data: {
        lease_id: leaseId,
      },
      success: (data) => {
        stopPageLoadingSpinner();
        data.parentComponent = that.props.parentComponent;
        initDialog(React.createElement(LeaseIndexModal, data), {
          renderContentType: 'reactElement',
          dialogId: 'mdc-dialog-allleases1',
        });
      },
    });
  }

  handleAttachments() {
    startPageLoadingSpinner();
    var that = this;

    $.ajax({
      url: '/attachment_redesign',
      type: 'GET',
      dataType: 'JSON',
      data: { parent_id: that.state.entityId, my_parent: 'tenant' },
      success: function (data) {
        initDialog(data.html, { acceptFunction: that.submitAttachments });
      },
      complete: function () {
        stopPageLoadingSpinner();
      },
    });
  }

  submitAttachments(closeDialogCallback) {
    this.updateHeaderFields(this.state.entityId);
    enableSubmit();
    closeDialogCallback();
  }

  render() {
    if (this.props.address != undefined) {
      var info = {
        address1: this.props.address.addressline1,
        address2: this.props.address.addressline2,
        city: this.props.address.city,
        zipcode: this.props.address.zipcode,
        country: this.props.address.country,
        state: this.props.address.state,
        email: this.props.email,
        number: this.props.number,
        pageType: this.props.pageType,
      };
    } else if (this.props.ownerInfo) {
      var info = {
        address1: this.props.addressline1,
        address2: this.props.addressline2,
        city: this.props.city,
        zipcode: this.props.zipcode,
        country: this.props.country,
        state: this.props.state,
        email: this.props.email,
        number: this.props.number,
        pageType: this.props.pageType,
      };
    } else {
      var info = {
        address1: '',
        address2: '',
        city: '',
        zipcode: '',
        country: '',
        state: '',
        email: '',
        number: '',
        pageType: '',
      };
    }

    if (this.props.role != undefined) {
      info.role = this.props.role;
    }
    if (this.props.securityDeposit != undefined) {
      info.securityDeposit = this.props.securityDeposit;
    }
    var firstLastNames = [];
    var additionalFields;
    var tenantsTableView;
    var allLeasesField;
    var attachmentsField;
    var entityName;
    var ownersField = [];
    var toolTipId = 'tool-tip-';
    if (this.props.pageType == 'vendor' || this.props.pageType == 'Vendor') {
      firstLastNames = (
        <div className="pm-flex pm-flex-2">
          <InfoField label={'First Name'} value={this.props.firstName} />
          <InfoField label={'Last Name'} value={this.props.lastName} />
        </div>
      );
      additionalFields = (
        <div className="pm-flex pm-flex-2">
          <InfoField
            label={'Allow Online Access'}
            value={this.props.onlineAccess ? 'Yes' : 'No'}
          />
          <InfoField
            label={'Active'}
            value={this.props.active ? 'Yes' : 'No'}
          />
          <div className="pm-flex">
            <InfoField label={'Tax ID'} value={this.props.taxId} />
          </div>
        </div>
      );
      entityName = (
        <div className="pm-flex">
          <InfoField label={'Name'} value={this.props.entityName} />
        </div>
      );
      if (this.props.lastName != null)
        toolTipId += this.props.lastName.toLowerCase();
    } else if (
      this.props.pageType == 'tenant' ||
      this.props.pageType == 'Tenant'
    ) {
      additionalFields = (
        <div className="pm-flex pm-flex-2">
          <InfoField label={'Role'} value={this.props.role} />
          <InfoField
            label={'Security Deposit'}
            value={this.props.securityDeposit}
            entityId={this.props.entityId}
            isVendor={this.props.isVendor}
          />
        </div>
      );
      if (this.props.maintenancePage && this.props.otherTenants) {
        let tenantTableRows = this.props.otherTenants.map(function (tenant) {
          return (
            <tr
              key={'tenant_' + tenant.id}
              className="pm-flex-space-between other-tenants-table"
            >
              <td className="pm-display-values--value-table">{tenant.name}</td>
              <td className="pm-display-values--value-table">{tenant.role}</td>
              <td className="pm-display-values--value-table">{tenant.phone}</td>
            </tr>
          );
        });
        if (this.props.otherTenants.length > 0) {
          tenantsTableView = (
            <div className="tool-tip-tenants-table-container">
              <div className="pm-display-values--label">All Tenants</div>
              <table className="tool-tip-tenants-table">
                <tbody>{tenantTableRows}</tbody>
              </table>
            </div>
          );
        }
      } else if (!this.props.maintenancePage) {
        allLeasesField = (
          <div className="pm-flex pm-flex-col pm-margin-bottom-20">
            <div className="pm-display-values">
              <div
                className="pm-display-values--value"
                title={this.props.value}
                style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
              >
                <a
                  id="all-leases"
                  className="pm-text-color--blue1 pm-text-link pm-text-size-14"
                  onClick={this.handleAllLeases}
                >
                  All Leases
                </a>
              </div>
            </div>
          </div>
        );
        attachmentsField = (
          <div className="pm-flex pm-flex-col pm-margin-bottom-20">
            <div className="pm-display-values">
              <div
                className="pm-display-values--value"
                title={this.props.value}
                style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
              >
                <a
                  id="tenant-attachments"
                  className="pm-text-color--blue1 pm-text-link pm-text-size-14"
                  onClick={this.handleAttachments}
                >
                  {this.state.accountingPage.state.attachmentsText}
                </a>
              </div>
            </div>
          </div>
        );
      }
      if (this.props.isVendor != 'true') {
        entityName = (
          <div className="pm-flex">
            <InfoField
              label={'Name'}
              value={this.props.entityName}
              onClick={this.navigateToTenant}
              style={{ color: '#0178d7', cursor: 'pointer' }}
            />
          </div>
        );
        toolTipId += this.props.entityId;
      } else {
        entityName = (
          <div className="pm-flex">
            <InfoField label={'Name'} value={this.props.entityName} />
          </div>
        );
        toolTipId += this.props.entityId;
      }
    } else if (this.props.pageType == 'owner') {
      if (this.props.owners != undefined && this.props.owners.length > 0) {
        ownersField = this.props.owners.map(function (owner, idx) {
          var ownerFullName = owner.firstName + ' ' + owner.lastName;
          return (
            <div key={idx} className="pm-flex pm-flex-3">
              <InfoField label={'Owner'} value={ownerFullName} />
              <InfoField label={'Email'} value={owner.email} />
              <InfoField label={'Phone'} value={owner.phone} />
            </div>
          );
        });
      }
    }
    let tooltipBody;
    if (this.props.noInfo) {
      tooltipBody = (
        <div id={'tool-tip-body_' + this.props.title} className="pm-padding-20">
          <h4 className="tooltip-no-entity">
            {'No ' + this.props.title + ' selected'}
          </h4>
        </div>
      );
    } else {
      tooltipBody = (
        <div id={'tool-tip-body_' + this.props.title} className="pm-padding-20">
          {entityName}

          {firstLastNames}

          <div className="pm-flex pm-flex-2">
            <InfoField label={'Address Line 1'} value={info.address1} />
            <InfoField label={'Address Line 2'} value={info.address2} />
          </div>
          <div className="pm-flex pm-flex-2">
            <InfoField label={'City'} value={info.city} />
            <InfoField label={'Zipcode'} value={info.zipcode} />
          </div>
          <div className="pm-flex pm-flex-2">
            <InfoField label={'Country'} value={info.country} />
            <InfoField label={'State'} value={info.state} />
          </div>

          {additionalFields}

          {this.props.pageType &&
            this.props.pageType != 'Site' &&
            this.props.pageType != 'owner' && (
              <div className="pm-flex">
                <InfoField label={'Primary Email'} value={info.email} />
              </div>
            )}
          {this.props.pageType &&
            this.props.pageType != 'Site' &&
            this.props.pageType != 'owner' && (
              <div className="pm-flex">
                <InfoField label={'Primary Phone'} value={info.number} />
              </div>
            )}

          {ownersField}
          {allLeasesField}
          {attachmentsField}
          {tenantsTableView}
        </div>
      );
    }
    return (
      <div
        id="tool-tip-lightbox"
        className="pm-surface mdc-elevation--z6 pm-bg-color--white pm-tenantinfo-tooltip"
      >
        <div
          id={'tool-tip-header_' + this.props.title}
          className="pm-surface--header pm-flex pm-flex-nowrap"
        >
          <h3>{info.pageType} Info</h3>
          {this.props.editable != false && (
            <button
              id={toolTipId}
              className="mdc-button mdc-button--dense mdc-button-icon mdc-button-row pm-text-color--blue1 pm-margin-left-20"
              data-mdc-auto-init="MDCRipple"
              onClick={this.handleEditInfo}
            >
              <i className="mdi mdi-pencil"></i>
            </button>
          )}
          <div className="pm-flex-grow"></div>
          <button
            className="pm-tenantinfo-tooltip--close mdc-button mdc-button--dense mdc-button-icon mdc-button-row pm-text-color--grey5"
            id={'close-' + toolTipId}
            onClick={this.handleCloseToolTip}
            data-mdc-auto-init="MDCRipple"
          >
            <i className="mdi mdi-close"></i>
          </button>
        </div>
        {tooltipBody}
      </div>
    );
  }
}

class InfoField extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...this.props };
    this._getSecurityDeposits = this._getSecurityDeposits.bind(this);
  }

  _getSecurityDeposits() {
    startPageLoadingSpinner();
    $.ajax({
      url: '/get_security_deposits_redesign/' + this.props.entityId,
      type: 'GET',
      dataType: 'JSON',
      success: function (data) {
        initDialog(data.html);
        stopPageLoadingSpinner();
      },
      error: function () {
        stopPageLoadingSpinner();
      },
    });
  }

  render() {
    if (this.props.entityId && this.props.isVendor != 'true') {
      var link = (
        <a
          id="security-deposits"
          className="pm-text-color--blue1 pm-text-link pm-text-size-14 pm-margin-left-16"
          onClick={this._getSecurityDeposits}
        >
          View all
        </a>
      );
    }

    var fieldStyle = Object.assign(
      { overflow: 'hidden', textOverflow: 'ellipsis' },
      this.props.style
    );

    return (
      <div className="pm-flex pm-flex-col pm-margin-bottom-20">
        <div className="pm-display-values">
          <div className="pm-display-values--label">{this.props.label}</div>
          <div
            className="pm-display-values--value"
            title={this.props.value}
            style={fieldStyle}
            onClick={this.props.onClick}
          >
            {this.props.value}
            {link}
          </div>
        </div>
      </div>
    );
  }
}

class BalanceCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...this.props };
    this.autoPay = this.autoPay.bind(this);
    this.submitAutoPay = this.submitAutoPay.bind(this);
  }

  componentWillReceiveProps(newProps) {
    this.setState(newProps);
  }

  cancelAutoPay(closeDialogCallback) {
    closeDialogCallback();
  }

  submitAutoPay(closeDialogCallback) {
    var that = this;

    $.ajax({
      url: '/confirm_auto_pay_redesign',
      type: 'GET',
      dataType: 'JSON',
      success: function (data) {
        initDialog(data.html);
        $('#confirm-auto-pay').on('click', function () {
          var action =
            $('#auto-pay-form')
              .attr('action')
              .split('auto_payment_method_relationships')[1] == ''
              ? '/create_redesign'
              : '/update_redesign';
          $.ajax({
            url: $('#auto-pay-form').attr('action') + action,
            type: 'POST',
            data: $('#auto-pay-form').serialize(),
            dataType: 'JSON',
            success: function (data) {
              if (data.errors == null) {
                closeDialogCallback();
                alert('Auto Pay is on');
                that.setState({ autoPayStatus: 'ON', autopay: true });
              } else alert(data.errors);
            },
          });
        });
      },
      complete: function () {
        enableSubmit();
      },
    });
  }

  autoPay() {
    var that = this;
    startLoadingSpinner('pm-main-content');
    $.ajax({
      url: '/auto_payment_method_relationships/new_redesign',
      type: 'GET',
      dataType: 'JSON',
      data: {
        lease_id: that.props.leaseId,
        tenant_id: that.state.entityId,
        vendor_role_relationship_id: that.state.vendorRoleRelationshipId,
      },
      success: function (data) {
        if (data.errors) {
          alert(data.errors);
        } else {
          initDialog(data.html, {
            acceptFunction: that.submitAutoPay,
            cancelFunction: that.cancelAutoPay,
          });
        }
      },
      complete: function () {
        stopLoadingSpinner('pm-main-content');
      },
    });
  }

  render() {
    var that = this;
    const style0 = {
      display: 'inline-block',
      marginRight: '0.625rem',
    };

    const style1 = {
      display: 'inline-block',
    };

    var autopayView = null;
    if (this.props.autopayAvailable) {
      var autoPayColor = that.state.autoPayStatus.includes('ON')
        ? 'pm-bg-color--green1'
        : 'pm-bg-color--red1';
      autopayView = (
        <button
          className={`pm-margin-left-16 mdc-button mdc-button--raised mdc-button--dense mdc-button--compact pm-text-color--white ${autoPayColor} autopay-${
            this.state.autoPayStatus === 'ON' ? 'enabled' : 'disabled'
          }`}
          data-mdc-auto-init="MDCRipple"
          onClick={this.autoPay}
          data-testing-id="auto-pay-button"
        >
          Auto Pay {this.state.autoPayStatus}
        </button>
      );
    }

    return (
      <div className="pm-flex pm-flex-col pm-flex-grow-none pm-flex-shrink-none pm-flex-align-right pm-hide-mobile">
        <div className="pm-flex pm-flex-align-center">
          <p style={style0} className="pm-text-color--grey5">
            Balance:
          </p>
          <h2 style={style1} className="pm-text-color--red1 pm-margin-none">
            {this.props.balanceAmount}
          </h2>
          {autopayView}
        </div>
      </div>
    );
  }
}

export class MobileFriendlyBalanceCard extends React.Component {
  toggelAutoPay() {
    $('button[data-testing-id="auto-pay-button"]').click();
  }

  render() {
    var autopayView = null;
    if (this.props.autopayAvailable) {
      autopayView = (
        <section className="mdc-card__actions pm-bg-color--white testing">
          <button
            className="mdc-button mdc-button--compact mdc-card__action pm-text-color--red1"
            onClick={this.toggelAutoPay}
          >
            Auto Pay {this.props.autoPayStatus}
          </button>
        </section>
      );
    }

    return (
      <div className="mdc-card pm-hide-desktop pm-margin-top-16">
        <section className="mdc-card__primary pm-bg-color--red1">
          <h2 className="mdc-card__subtitle pm-text-color--white">
            Fee Balance:
          </h2>
          <h1 className="mdc-card__title mdc-card__title--large pm-text-color--white">
            {this.props.balanceAmount}
          </h1>
        </section>
        {autopayView}
      </div>
    );
  }
}
